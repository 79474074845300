// @use '~@cloudscape-design/design-tokens-themed/visual-refresh.scss' as cs;
// @use '~@cloudscape-design/design-tokens-themed/visual-refresh' as csi;
@use '~@cloudscape-design/design-tokens' as cs;
@use '~@cloudscape-design/design-tokens/index' as csi;

:root {
  --storm-color: #00a486;
  --error-color: indianred;
}

body {
  background: cs.$color-background-layout-main;
  position: relative;
}

.centerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightContent {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

#header {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0;
  background-color: #0f1b2a;
  font-family: cs.$font-family-base;
}

#header span {
  overflow: visible;
}

ul.menu-list {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0 40px;
  list-style: none;
  font-size: 14px;

  &>li {
    padding: 0;
    margin: 0;
    margin-right: 8px;

    >a {
      padding: 0 6px;
    }

    a,
    div,
    button,
    input,
    label {
      float: left;
      color: cs.$color-text-interactive-default;
      line-height: 16px;
    }

    #visual-refresh-toggle {
      margin-right: 5px;
      margin-top: 1px;
    }

    a,
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.title {
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 493px) {
    padding: 4px 20px;
    flex-wrap: wrap;
    height: fit-content;

    .title {
      flex: 1 1 100%;
      margin-bottom: 8px;
    }

    li {
      width: min-content;

      button,
      a {
        text-align: left;
      }

      a {
        padding: 0;
      }
    }
  }
}

.nobullet {
  list-style-type: none;
}

.MetadataTable {
  td {
    padding-left: 8px;
  }
}

.SplitPanelWrapper {
  >div {
    >div:last-child>div:nth-child(1) {
      padding: 0px 8px !important;

      >div {
        margin: 8px 0px !important;
      }
    }
  }

  :has(.minHeight300) &>div:has(> div + div) {
    min-height: 300px !important;
    overflow: hidden !important;
  }

  :has(.minHeight320) &>div:has(> div + div) {
    min-height: 320px !important;
    overflow: hidden !important;
  }

  :has(.minHeight360) &>div:has(> div + div) {
    min-height: 360px !important;
    overflow: hidden !important;
  }

  :has(.minHeight400) &>div:has(> div + div) {
    min-height: 400px !important;
    overflow: hidden !important;
  }
}

.table_scroll_hidden {
  >div {
    >div:nth-child(2) {
      >div:nth-child(2) {
        visibility: hidden !important;
      }
    }
  }
}

button.warn {
  color: cs.$color-text-status-warning !important;
  border-color: cs.$color-text-status-warning !important;
  background-color: cs.$color-background-status-warning !important;

  &:hover {
    background-color: cs.$color-text-status-warning !important;
    color: cs.$color-background-status-warning !important;
  }
}

.FileInput {
  padding: 0 !important;
  pointer-events: none;

  >div {
    pointer-events: all;
    padding: csi.$space-scaled-xxxs
  }
}

.InitialLineHeight {
  line-height: initial !important;
}

.NoAutosuggestIcon {
  :has(input) {
    input {
      padding-left: 8px !important;
    }

    >span:first-child {

      &,
      & * {
        display: none !important;
        visibility: hidden !important;
        content-visibility: hidden !important;
        width: 0px !important;
      }
    }
  }
}

.NoWrap {
  white-space: nowrap !important;
}

.FullWidth {
  width: 100%;
}

.AlignBottom {
  vertical-align: bottom
}

.CustomWizard {
  &>div:first-of-type {
    &>nav:first-of-type {
      padding-top: 36px !important;
    }
  }
}

.columns {
  display: flex; /* Use flexbox to create two columns */
  justify-content: space-between; /* Space the columns evenly */
}

.column {
  width: 45%; /* Set the width of each column (adjust as needed) */
}